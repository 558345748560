import {Fragment} from 'react'

import type {Shortcut} from '../hooks/shortcuts'

export function KeyboardVisual({shortcut}: {shortcut: Shortcut}) {
  return (
    <>
      {shortcut.text?.split(' ').map(keyValue => {
        return (
          <Fragment key={keyValue}>
            <kbd>{keyValue}</kbd>{' '}
          </Fragment>
        )
      })}
    </>
  )
}

try{ KeyboardVisual.displayName ||= 'KeyboardVisual' } catch {}